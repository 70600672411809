import { Storage } from 'aws-amplify'

export const uploadFileS3 = (fileData) => {
  const { PATH, fileName, file } = fileData
  return async (dispatch) => {
    try {
      const imageUploadRes = await Storage.put(PATH + fileName, file, {
        contentType: file.type // contentType is optional
      })
      console.log(imageUploadRes)
      return imageUploadRes
    } catch (e) {
      console.log(e.response)
    }
  }
}
