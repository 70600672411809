import constants from '@src/constants'
import Compressor from 'compressorjs'
import { Badge } from 'reactstrap'

// ** Alert Component
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { uploadFileS3 } from '../redux/actions/fileUpload'

const MySwal = withReactContent(Swal)

// ** Show Alert to confirm
export const showConfirmAlert = ({ title, message, ...otherProps }) => {
  return MySwal.fire({
    title: title?.length
      ? `<span class="text-break h3 font-weight-bolder">${title}</span>`
      : title !== null
      ? 'Are you sure?'
      : '',
    text: message,
    icon: 'question',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-secondary ml-2'
    },
    buttonsStyling: false,
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Ok',
    showCancelButton: true,
    focusCancel: false,
    showLoaderOnConfirm: true,
    // preConfirm,
    ...otherProps
  })
}

export const getStatusBadge = (status) => {
  let statusColor
  let statusBgColor
  // let statusText;
  if (status === 'accepted') {
    statusColor = '#075405'
    statusBgColor = '#D2F113'
  } else if (status === 'completed') {
    statusColor = '#D2F113'
    statusBgColor = '#075405'
  } else if (status === 'pending') {
    statusColor = '#FFFFFF'
    statusBgColor = '#5F5F5F'
  } else if (status === 'declined') {
    statusColor = '#A10C0C'
    statusBgColor = '#FF9595'
  } else if (status === 'cancelled') {
    statusColor = '#FF9595'
    statusBgColor = '#A10C0C'
  }
  return (
    <Badge
      style={{
        color: statusColor,
        backgroundColor: statusBgColor
      }}
      className="text-capitalize"
    >
      {status}
    </Badge>
  )
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  return (
    // localStorage.getItem("userData") &&
    localStorage.getItem(constants.accessToken)
  )
}

// export const isUserLoggedIn = () => true
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#437ABE1a', // '#7367f01a', // for option hover bg-color
    primary: '#437ABE', // for selected option bg-color
    neutral10: '#437ABE', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const createShallowCopy = (obj) => JSON.parse(JSON.stringify(obj))

export const delayTestFunction = async (milliseconds) => {
  return new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve(true)
    }, milliseconds)
  })
}

export const apiUrlGenerator = (object) => {
  const ret = []
  for (let d in object) {
    if (object[d]) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(object[d]))
    }
  }
  return decodeURI(ret.join('&'))
}

export const compressImgOrDocUploader = ({
  file,
  uploadPath,
  fileName,
  maxWidth
}) => {
  // const dispatch = store
  return async (dispatch) => {
    try {
      if (!file) {
        throw Error('File is required')
      }
      if (!uploadPath) {
        throw Error('Upload Path is required')
      }
      if (file?.type?.startsWith('image')) {
        const addImageProcess = (src) => {
          return new Promise((resolve, reject) => {
            const imgFile = file
            const img = document.createElement('img')
            img.src = window.URL.createObjectURL(imgFile)
            img.onload = () => {
              const imageWidth = img?.naturalWidth
              const imageHeight = img?.naturalHeight
              new Compressor(imgFile, {
                quality: 0.8,
                strict: true,
                maxWidth: maxWidth
                  ? maxWidth
                  : imageWidth / imageHeight > 1
                  ? 1920
                  : 1080,
                success: async (compressedFile) => {
                  console.log(compressedFile)
                  const s3ImgFileRes = await dispatch(
                    uploadFileS3({
                      PATH: uploadPath,
                      fileName: fileName ?? compressedFile?.name,
                      file: compressedFile
                    })
                  )
                  resolve(s3ImgFileRes)
                  return s3ImgFileRes
                }
              })
            }
          })
        }
        const imgFileRes = await addImageProcess()
        return imgFileRes
      } else {
        const s3ImgFileRes = await dispatch(
          uploadFileS3({
            PATH: uploadPath,
            fileName: fileName ?? file?.name,
            file: file
          })
        )
        return s3ImgFileRes
      }
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}

export const customLog = (...logMessage) => {
  // if (process.env.NODE_ENV === "development") {
  console.log(...logMessage)
  // }
}
