import Pusher from 'pusher-js';
import constants from '../constants';
// import { isUserLoggedIn } from "../utility/Utils";

// PRODUCTION
const REACT_APP_PUSHER_APP_ID = 'HSqoOhK2fPkKVs2id5sSYhX9';
const REACT_APP_PUSHER_APP_KEY = 'iFaPRwHfBJ1GbUHLMMjtUk6k';
const REACT_APP_PUSHER_APP_SECRET = 'KoMq3Z5o4R6d4Jf0MQcsXtmX';
const REACT_APP_PUSHER_HOST = 'transport-chat.bissellpetfoundation.org';
const REACT_APP_PUSHER_PORT = '443';
const REACT_APP_PUSHER_SCHEME = 'https';
const REACT_APP_PUSHER_APP_CLUSTER = 'mt1';
const REACT_USER_AUTH_ENDPOINT = '/broadcasting/auth';

// SANDBOX
// const REACT_APP_PUSHER_APP_ID = 'HSqoOhK2fPkKVs2id5sSYhX9';
// const REACT_APP_PUSHER_APP_KEY = 'iFaPRwHfBJ1GbUHLMMjtUk6k';
// const REACT_APP_PUSHER_APP_SECRET = 'KoMq3Z5o4R6d4Jf0MQcsXtmX';
// const REACT_APP_PUSHER_HOST = 'transport-chat-sandbox.bissellpetfoundation.org';
// const REACT_APP_PUSHER_PORT = '443';
// const REACT_APP_PUSHER_SCHEME = 'https';
// const REACT_APP_PUSHER_APP_CLUSTER = 'mt1';
// const REACT_USER_AUTH_ENDPOINT = '/broadcasting/auth';

// Pusher.logToConsole = true;
// Pusher.log = function (message) {
//   console.log(message); // custom logging logic
// };
export const PusherJS = () => {
  const accessToken = localStorage.getItem(constants.accessToken);
  return new Pusher(REACT_APP_PUSHER_APP_KEY, {
    wsHost: REACT_APP_PUSHER_HOST,
    wsPort: REACT_APP_PUSHER_PORT,
    wssPort: REACT_APP_PUSHER_PORT,
    forceTLS: true,
    encrypted: true,
    disableStats: true,
    reconnectionDelay: 2000,
    // activityTimeout: 2000, // time gap between reconnection attempts (in milliseconds)
    cluster: REACT_APP_PUSHER_APP_CLUSTER,
    authEndpoint: `${constants?.API_BASE_URL}${REACT_USER_AUTH_ENDPOINT}`,
    auth: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
      }
    }
  });
};

