export default {
  accessToken: 'accessToken',
  isSourceOrganization: 'isSourceOrganization',
  USERPROFILE_UPLOAD_PATH: 'users/profile_images/',
  HEALTH_DOC_UPLOAD_PATH: 'users/health_certificates/',
  PET_IMAGES_VIDEOS_PATH: 'pets/files/',
  PET_MEDICAL_RECORD_PATH: 'pets/medical_records/',
  CHAT_ATTACHED_DOC_PATH: 'message_attachments/',
  TRANSPORT_REQUEST_ATTACHMENTS_PATH: 'transport_request_attachments/',
  //API_BASE_URL: 'https://transport-api.bissellpetfoundation.org/api'
  API_BASE_URL: 'https://transport-api-sandbox.bissellpetfoundation.org/api'
};

