// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './auth';
import chat from './chat';
import navbar from './navbar';
import layout from './layout';
import filter from './filter';

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  chat,
  filter
});

export default rootReducer;
