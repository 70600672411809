import axios from '../../../axios'

// Add Pets Handler
export const addPetsHandler = (newPetsData) => {
  return async (dispatch) => {
    try {
      const addPetsRes = await axios.post('/pets', newPetsData)
      return addPetsRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Add Pets Handler
export const addDraftPetsHandler = (newDraftPetsData) => {
  return async (dispatch) => {
    try {
      const addDraftPetsRes = await axios.post('/drafts', newDraftPetsData)
      return addDraftPetsRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Delete Draft Pet Handler
export const deleteDraftPetHandler = (draftPetId) => {
  return async (dispatch) => {
    try {
      const deleteDraftPetRes = await axios.delete(`/drafts/${draftPetId}`, {})
      return deleteDraftPetRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Delete Pet Image and Video Handler
export const deletePetImageVideoHandler = (deleteData) => {
  return async (dispatch) => {
    try {
      const deletePetImageVideoRes = await axios.delete('/pet-files', {
        data: deleteData
      })
      return deletePetImageVideoRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Delete Pet Medical Record Handler
export const deleteMedicalRecordHandler = (deleteRecordData) => {
  return async (dispatch) => {
    try {
      const deleteMedicalRecordRes = await axios.delete(
        '/pet-medical-records',
        {
          data: deleteRecordData
        }
      )
      return deleteMedicalRecordRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Get Pet Base Colors
export const getPetBaseColors = () => {
  return async (dispatch) => {
    try {
      const petBaseColorsRes = await axios.get('/pets/base-colors')
      return petBaseColorsRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Get Pet Breeds Lists
export const getPetBreeds = () => {
  return async (dispatch) => {
    try {
      const petBreedsRes = await axios.get('/pets/breeds')
      return petBreedsRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Get Pet Unique Medical Conditions
export const getPetUniqueMedical = () => {
  return async (dispatch) => {
    try {
      const petUniqueMedicalRes = await axios.get(
        '/pets/unique-medical-conditions'
      )
      return petUniqueMedicalRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Get Pet Temperaments
export const getPetTemperaments = () => {
  return async (dispatch) => {
    try {
      const petTemperamentsRes = await axios.get('/pets/temperaments')
      return petTemperamentsRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Get Pet HeartWorm(HW+, HW-) Status
export const getPetHWStatus = () => {
  return async (dispatch) => {
    try {
      const petHWRes = await axios.get('/pets/heartworm-statues')
      return petHWRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// Get Pet  FeLV Status
export const getPetFeLVStatus = () => {
  return async (dispatch) => {
    try {
      const petFeLVRes = await axios.get('/pets/fiv-felv-statues')
      return petFeLVRes.data
    } catch (e) {
      throw e.response
    }
  }
}
