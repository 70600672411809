// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyBg6R9pK1HekhtFL2tDTgmbqSve4DiDo5Q',
  authDomain: 'bissell-pet-foundation-3eff7.firebaseapp.com',
  projectId: 'bissell-pet-foundation-3eff7',
  storageBucket: 'bissell-pet-foundation-3eff7.appspot.com',
  messagingSenderId: '501139876618',
  appId: '1:501139876618:web:1b5ba657348075dbf1e8f3',
  measurementId: 'G-K4R7MPNMQE'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
