import {
  LOGIN,
  LOGOUT,
  TOGGLE_ACC_TYPE,
  SIGNUP,
  SETUP_DATA
} from '../../actionTypes/auth';
// import {firebase} from "firebase/app";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  updatePassword
} from 'firebase/auth';
import { auth } from '../../../firebase/firebase';
import axios from '../../../axios';
import constants from '@src/constants';

// ** Handle User Login
export const handleLogin = (data) => {
  localStorage.removeItem(constants.accessToken);

  const { userLoginData } = data;
  const { email, password } = userLoginData;
  return async (dispatch) => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const firebaseResponse = await res?._tokenResponse;
      if (firebaseResponse.registered) {
        const loginParams = {
          login_type: 'email',
          firebase_token: firebaseResponse?.idToken
        };
        try {
          const loginResponse = await axios.post('/login', loginParams);
          const loginUserData = loginResponse.data.payload;

          localStorage.setItem(
            constants.accessToken,
            loginUserData.access_token
          );
          localStorage.setItem(
            constants.isSourceOrganization,
            'isSourceOrganization'
          );

          dispatch({
            type: LOGIN,
            data: { ...loginUserData },
            isAdmin: loginUserData.role === 'admin' ? true : false
          });

          return loginUserData;
        } catch (err) {
          throw new Error(err?.message);
        }
      }
      // ** Add to user, accessToken & refreshToken to localStorage
    } catch (err) {
      if (err?.name === 'FirebaseError') {
        throw new Error(err?.code?.split('auth/')[1]);
      } else {
        throw new Error(err?.message);
      }
    }
  };
};

// ** Handle User Sign Up
export const handleSignUp = (data) => {
  const { userSignUpData } = data;
  const { email, password } = userSignUpData;
  return async (dispatch) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const firebaseResponse = await res._tokenResponse;

      dispatch({
        type: SIGNUP,
        firebaseResponse
      });
      return {
        idToken: firebaseResponse.idToken
      };
    } catch (err) {
      throw new Error(err?.code);
    }
  };
};

// ** Handle User Logout
export const handleLogout = (data) => {
  return async (dispatch) => {
    localStorage.removeItem(constants.isSourceOrganization);
    localStorage.removeItem('userData');

    try {
      if (!data?.wrongToken) {
        try {
          const logoutRes = await axios.post('/logout', {});
        } catch (err) {}
      }

      localStorage.removeItem(constants.accessToken);
      dispatch({
        type: LOGOUT
      });
    } catch (e) {
    } finally {
      window.location = '/';
    }
  };
};

// ** Handle User Accout Type
export const handleToggleAccType = () => {
  return (dispatch) => {
    const isSourceOrganization = localStorage.getItem(
      constants?.isSourceOrganization
    );
    localStorage.setItem(
      constants?.isSourceOrganization,
      !!isSourceOrganization ? '' : 'isSourceOrganization'
    );

    dispatch({
      type: TOGGLE_ACC_TYPE
    });
  };
};

// ** Handle Setup Data Initially
export const handleSetupData = (parsedUserData) => {
  return async (dispatch) => {
    try {
      const setUpResponse = await axios.get('/show-profile');

      const setUpUserData = setUpResponse.data.payload;

      if (setUpResponse.data.result) {
        const isSourceOrganization = localStorage.getItem(
          constants?.isSourceOrganization
        );

        dispatch({
          type: SETUP_DATA,
          data: { ...setUpUserData },
          isAdmin: setUpUserData.role === 'admin' ? true : false,
          isSourceOrganization: !!isSourceOrganization ? true : false
        });
      }
    } catch (err) {
      throw new Error(err);
    }
  };
};

// ** Handle User Login
export const handleChangePassword = (userChangePassData) => {
  // localStorage.removeItem(constants.accessToken);

  const { currPassword, newPassword } = userChangePassData;
  return async (dispatch) => {
    // return;
    try {
      const user = auth?.currentUser;

      // First, re-authenticate the user with their current password
      const credentials = EmailAuthProvider.credential(
        user.email,
        currPassword
      );

      const reAuthenticateRes = await reauthenticateWithCredential(
        user,
        credentials
      );

      // If re-authentication is successful, update the user's password
      const updatePassRes = await updatePassword(user, newPassword);

      return;
    } catch (err) {
      if (err?.name === 'FirebaseError') {
        throw new Error(err?.code?.split('auth/')[1]);
      } else {
        throw new Error(err?.message);
      }
    }
  };
};

// ** Handle Forgot Password
export const handleForgotPassword = (emailData) => {
  return async (dispatch) => {
    try {
      const forgotPasswordRes = await axios.post('/forgot-password', emailData);
      return forgotPasswordRes.data;
    } catch (err) {
      throw err?.response?.data;
    }
  };
};

