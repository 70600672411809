import axios from 'axios';
import constants from '@src/constants';
import { store } from '../redux/storeConfig/store';
import { Fragment } from 'react';
import { toast } from 'react-toastify';
import { handleLogout } from '../redux/actions/auth';

axios.defaults.baseURL = constants?.API_BASE_URL;

export const ErrorToastComponent = (props) => {
  const { title, message } = props;

  return (
    <Fragment>
      <div className="toastify-header pb-0">
        <div className="title-wrapper user-select-none">
          {/* <Avatar size='sm' color={color} icon={icon} /> */}
          <h6 className="toast-title">{message}</h6>
        </div>
      </div>
    </Fragment>
  );
};

const delay = async (milliseconds) => {
  return new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve(true);
    }, milliseconds);
  });
};

axios.interceptors.request.use(
  (reqData) => {
    const accessToken = localStorage.getItem(constants.accessToken);

    const baseHeaderObj = {
      Authorization: `Bearer ${accessToken}`
    };
    reqData.headers = {
      ...reqData.headers,
      ...baseHeaderObj
    };
    return reqData;
  },
  (error) => {
    // Do something with request error

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (resData) => {
    return resData;
  },
  async (err) => {
    console.log(err);
    const error = err?.response;

    if (err?.message === 'Network Error') {
      toast.error(
        <ErrorToastComponent
          title={'Error'}
          message={err?.message || 'Something went wrong!'}
        />,
        {
          autoClose: 1500,
          hideProgressBar: true,
          closeButton: true
        }
      );
    } else if (error?.status === 401) {
      toast.error(
        <ErrorToastComponent
          title={'Error'}
          message={error?.data?.message || 'Something went wrong!'}
        />,
        {
          autoClose: 1500,
          hideProgressBar: true,
          closeButton: true
        }
      );

      const delayRes = await delay(2000);

      store.dispatch(handleLogout({ wrongToken: true }));
    } else if (error?.status === 404) {
      toast.error(
        <ErrorToastComponent
          title={'Error'}
          message={error?.data?.message || 'Something went wrong!'}
        />,
        {
          autoClose: 1500,
          hideProgressBar: true,
          closeButton: true
        }
      );

      // const delayRes = await delay(1500);

      // window.location = "/error";
    } else if (err.response.data.message) {
      toast.error(err?.response?.data?.message ?? 'Something went wrong!');
    }

    return Promise.reject(err);
  }
);

export default axios;

