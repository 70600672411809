import { isUserLoggedIn } from "../../../utility/Utils";
import {
  LOGIN,
  LOGOUT,
  TOGGLE_ACC_TYPE,
  SIGNUP,
  SETUP_DATA,
} from "../../actionTypes/auth";

// **  Initial State
const initialState = {
  userData: {},
  isSourceOrganization: true,
  isAdmin: false,
  isUserLoggedInState: !!isUserLoggedIn(),
  // isUserLoggedInState: false,
};

const authReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SETUP_DATA:
      return {
        ...state,
        // ...action.data,
        isAdmin: action.isAdmin,
        isSourceOrganization: action.isSourceOrganization,
        userData: action.data,
        isUserLoggedInState: !!isUserLoggedIn(),
      };

    case LOGIN:
      return {
        ...state,
        isAdmin: action.isAdmin,
        userData: action.data,
        // [config.storageTokenKeyName]: action[config.storageTokenKeyName],
      };

    case SIGNUP:
      console.log("signup");

    // console.log("state", state);
    // console.log("action", action);
    // return {
    //   ...state,
    //   ...action.data,
    //   // userData: action.data,
    //   // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
    //   // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
    // };
    case LOGOUT:
      const obj = { ...action };
      delete obj.type;

      return {
        ...state,
        userData: {},
        ...obj,
        isUserLoggedInState: !!isUserLoggedIn(),
      };

    case TOGGLE_ACC_TYPE:
      return {
        ...state,
        isSourceOrganization: !state.isSourceOrganization,
      };
    default:
      return state;
  }
};
export default authReducer;
