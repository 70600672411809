import axios from '../../../axios'
import { apiUrlGenerator } from '../../../utility/Utils'
import {
  GET_SELECTED_USER_CHAT,
  GET_USERS_LISTS,
  SELECT_USER,
  SEND_RECEIVE_CHAT_MESSAGE,
  USER_CHAT_PAGINATION
} from '../../actionTypes/chat'
import { store } from '../../storeConfig/store'

// Add Send New Message Handler
export const sendNewMessageHandler = (newMessageData) => {
  return async (dispatch) => {
    try {
      const sendNewMessageRes = await axios.post(
        '/threads/messages',
        newMessageData
      )

      const currSelectedUser = store.getState().chat.selectedUser

      if (
        sendNewMessageRes?.data?.payload?.thread_id === currSelectedUser?.id
      ) {
        dispatch({
          type: SEND_RECEIVE_CHAT_MESSAGE,
          data: sendNewMessageRes?.data?.payload
        })
      }
      return sendNewMessageRes.data
    } catch (e) {
      throw e.response
    }
  }
}

export const getAllOrgList = (filteredData) => {
  const apiParams = apiUrlGenerator(filteredData)

  return async (dispatch) => {
    try {
      const newChatOrgListNew = await axios.get(
        `/organizations/dropdown-list?${apiParams}`
      )

      return newChatOrgListNew.data
    } catch (e) {
      throw e.response
    }
  }
}

// Get Chat Users Lists Handler (Sidebar List)
export const getChatUsersList = (filteredData) => {
  const apiParams = apiUrlGenerator(filteredData)

  return async (dispatch) => {
    try {
      const getChatUsersListsRes = await axios.get(
        `/users/threads?${apiParams}`
      )

      dispatch({
        type: GET_USERS_LISTS,
        data: getChatUsersListsRes.data.payload,
        isSearch: !!filteredData?.search
      })
      return getChatUsersListsRes.data
    } catch (e) {
      throw e.response
    }
  }
}

// get Selected User Chats Handler
export const getSelectedUserChats = (apiData) => {
  const { groupId, filteredData } = apiData

  const apiParams = apiUrlGenerator(filteredData)

  return async (dispatch) => {
    try {
      const getSelectedUserChatsRes = await axios.get(
        `/users/threads/${groupId}/messages?${apiParams}`
      )

      if (filteredData?.message_id === '') {
        dispatch({
          type: GET_SELECTED_USER_CHAT,
          data: getSelectedUserChatsRes?.data.payload
        })
      } else {
        dispatch({
          type: USER_CHAT_PAGINATION,
          data: getSelectedUserChatsRes?.data.payload
        })
      }

      return getSelectedUserChatsRes.data
    } catch (e) {
      dispatch({
        type: GET_SELECTED_USER_CHAT,
        data: []
      })

      throw e.response
    }
  }
}

export const handleUserSelection = (userData) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SELECT_USER,
        data: userData
      })
    } catch (e) {
      throw e.response
    }
  }
}

export const sendChatMessage = (apiData) => {
  const { groupId, messages } = apiData

  return async (dispatch) => {
    try {
      const sendChatMessageRes = await axios.post(
        `/threads/${groupId}/messages`,
        messages
      )

      dispatch({
        type: SEND_RECEIVE_CHAT_MESSAGE,
        data: sendChatMessageRes?.data?.payload
      })
      return sendChatMessageRes.data
    } catch (e) {
      throw e.response
    }
  }
}

export const handleUserRead = (apiData) => {
  const { groupId } = apiData

  return async (dispatch) => {
    try {
      const userReadRes = await axios.post(
        `/threads/${groupId}/messages/read`,
        {}
      )

      return userReadRes.data
    } catch (e) {
      throw e.response
    }
  }
}

export const handleAddAdmin = (apiData) => {
  const { groupId, uploadGroupData } = apiData

  return async (dispatch) => {
    try {
      const addAdminRes = await axios.post(
        `/threads/${groupId}/add-admin`,
        uploadGroupData
      )

      return addAdminRes.data
    } catch (e) {
      throw e.response
    }
  }
}

export const handleUpdateThreadData = (apiData) => {
  const { groupId, uploadGroupData } = apiData

  return async (dispatch) => {
    try {
      const addAdminRes = await axios.put(
        `/users/threads/${groupId}`,
        {},
        { params: uploadGroupData }
      )

      return addAdminRes.data
    } catch (e) {
      throw e.response
    }
  }
}

export const getGroupMemberLists = (filteredData, groupId) => {
  const apiParams = apiUrlGenerator(filteredData)

  return async (dispatch) => {
    try {
      const groupMemberListRes = await axios.get(
        `/threads/${groupId}/members?${apiParams}`
      )

      return groupMemberListRes.data
    } catch (e) {
      throw e.response
    }
  }
}
