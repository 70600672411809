// ** Initial State
const initialState = {
  filters: {},
  pullRequestFilters: {}
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_FILTERS':
      return { ...state, filters: action.val };

    case 'UPDATE_PULL_REQUEST_FILTERS':
      return { ...state, pullRequestFilters: action.val };

    default:
      return state;
  }
};

export default filtersReducer;

